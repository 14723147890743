import React, { useState, useEffect } from "react";
import GoogleIcon from '@mui/icons-material/Google';
import { authActions } from "../../redux-store";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import sessionstorage from "sessionstorage";
import backend from "../../api";
import { useNavigate } from "react-router-dom";
// import './Auth.css';
import { useLocation } from 'react-router-dom';
import { motion, easeOut, AnimatePresence } from 'framer-motion';
import Classes from './Auth.module.css'

import CryptoJS from 'crypto-js';

const LoadingSpinner = () => (
  <div class="loadingio-spinner-eclipse-nq4q5u6dq7r">
    <div class="ldio-x2uulkbinbj">
      <div></div>
    </div>
  </div>
)

function Auth() {
  const [width, setWidth] = useState(window.innerWidth);
  const [state, setState] = useState("signin");
  const { user } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [fullname, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [loading, setLoading] = useState();
  const [incorrectError, setincorrectError] = useState(false);
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [showEye, setshowEye] = useState(false);
  const [showEye2, setshowEye2] = useState(false);
  const [passwordMatchingError, setpasswordMatchingError] = useState(false);
  const [emailSentMsg, setemailSentMsg] = useState(false);
  const [invalidEmailError, setinvalidEmailError] = useState(false);
  const [passwordLengthError, setpasswordLengthError] = useState(false);
  const [userAlreadyExistError, setUserAlreadyExistError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [codeSentMsg, setcodeSentMsg] = useState(false);
  const [userCode, setUserCode] = useState('');
  const [userId, setUserId] = useState('');
  const companyInfo = useSelector((state) => state.companyInfo.info);

  const location = useLocation();


  const code = Math.floor(Math.random() * 10000).toString();

  const message = `${code}`;

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  const switchToSignUp = () => {
    setincorrectError(false);
    // setState("signup");
    navigateTo('/signup')
  }
  const switchToVerify = () => {
    setincorrectError(false);
    if (password !== confirmPassword) {
      setpasswordMatchingError(true);
      setTimeout(() => {
        setpasswordMatchingError(false);
      }, 5000);
      return;
    }

    if (password && password.length <= 5) {
      setpasswordLengthError(true);
      setTimeout(() => {
        setpasswordLengthError(false);
      }, 10000);
      return;
    }

    sendMail();
  }

  const switchToSignIn = () => {
    setincorrectError(false);
    setState("signin");
  }

  const sendMail = async () => {
    setLoading(true);

    try {
      const encrypted = CryptoJS.AES.encrypt(code, 'secret-key').toString();
      sessionStorage.setItem('encryptedData', encrypted);
      const encryptedDataFromStorage = sessionStorage.getItem('encryptedData');

      if (encryptedDataFromStorage) {
        const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, 'secret-key');
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        // Optionally use decryptedData if needed
      }

      const body = {
        username: "User",
        email: email,
        message
      };
      const resp = await backend.post("/verify/sendemail", body);

      if (resp.status === 200) {
        // console.log('Verification code sent successfully');
        setcodeSentMsg(true);
        setTimeout(() => {
          setcodeSentMsg(false);
        }, 60000);
        setState('verify');
      } else if (resp.status === 400) {
        setUserAlreadyExistError(true);
        setTimeout(() => {
          setUserAlreadyExistError(false);
        }, 10000);
      } else if (resp.status === 500) {
        alert('Server error occurred. Please try again later.');
      } else {
        // Handle other unexpected statuses
        alert('An unexpected error occurred. Please try again.');
      }
    } catch (error) {
      // This block will now only catch network errors or issues in the request setup,
      // not HTTP errors like 400 or 500 which are handled above
      console.log(error?.response?.data?.message);
      setUserAlreadyExistError(true);
      setErrorMessage(error?.response?.data?.message);
      setTimeout(() => {
        setUserAlreadyExistError(false);
        setErrorMessage('');
      }, 10000);
      // alert(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const companyId = companyInfo?._id;

  const loginSubmitHandler = async (e) => {
    e.preventDefault();
    setincorrectError(false);
    setLoading(true)
    const sideBar = sessionStorage.getItem('navBtn');

    const isLogin = await dispatch(authActions.login(email, password, companyId));
    // console.log(dispatch(authActions.login(email, password)))

    if (isLogin) {

      // handleLogout()
      // await getProjectById();
      setLoading(false)
      if (sideBar == "Pitch Deck Templates") {
        navigateTo(`/templates`);
      } else {
        navigateTo("/screening-dashboard");
      }
    }
    if (!isLogin) {
      setLoading(false)
      // alert("Wrong Credentials");
      setincorrectError(true);
    }
  };

  const MoveToSubdomain = (e) => {
    e.preventDefault();
    window.location.href = `https://${companyName}.evala.ai`;
};

  const SignUpHandler = async () => {
    // e.preventDefault();
    const sideBar = sessionStorage.getItem('navBtn');
    setLoading(true)
    try {
      if (email != "" && email != null && email != undefined &&
        password != "" && password != null && password != undefined
      ) {
        if (email.includes("@") && password.length > 5) {
          const isSignup = await dispatch(
            authActions.signUp(email, password, fullname)
          );
          if (isSignup) {
            setLoading(false)
            console.log(isSignup)
            // setState(4)
            if (location?.state?.data === "toTemplate") {
              navigateTo(`/templates`);
            } else {
              navigateTo("/screening-dashboard");
            }
          }
          if (!isSignup) {
            setLoading(false)
            setincorrectError(true);
          }
        }
        else {
          setLoading(false)
          setpasswordLengthError(true);
          setTimeout(() => {
            setpasswordLengthError(false);
          }, 10000);
        }
      } else {
        setLoading(false)
        setpasswordLengthError(true);
        setTimeout(() => {
          setpasswordLengthError(false);
        }, 10000);
      }
    } catch (e) {
      setLoading(false)
      alert("Network Error");
    }
  };

  // Define the async function
  const fetchUserId = async () => {
    try {
      // setLoading(true); // Set loading to true at the beginning of the request
      const res = await backend.get(`/user?email=${email}`);
      console.log(res.data)
      setUserId(res?.data._id);
      // setFilteredFiles(res?.data);
      console.log(res?.data);
    } catch (error) {
      console.error("Failed to fetch user id:", error);
      // Handle error state as needed
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };

  const resetLink = `https://www.pitchperfecter.ai/createpassword/${userId}/`;
  const ForgotHandler = async (e) => {
    e.preventDefault();
    setLoading(true)
    fetchUserId();

    try {
      const isforgot = await dispatch(authActions.forgotPassword(email, resetLink));
      if (isforgot) {
        setLoading(false)
        setinvalidEmailError(false);
        setemailSentMsg(true);
        setTimeout(() => {
          setinvalidEmailError(false);
          setemailSentMsg(false);
        }, 10000);
      } else {
        setLoading(false)
        setemailSentMsg(false);
        setinvalidEmailError(true);
        setTimeout(() => {
          setinvalidEmailError(false);
          setemailSentMsg(false);
        }, 10000);
      }
    } catch (err) {
      setLoading(false)
      alert('Network error')
    }
  }

  function onCLickVerify(e) {
    e.preventDefault();
    const encryptedDataFromStorage = sessionStorage.getItem('encryptedData');
    if (encryptedDataFromStorage) {
      const bytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, 'secret-key');
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

      if (userCode === decryptedData) {
        SignUpHandler();
      } else {
        // toast.error('Invalid code');
        setincorrectError(true);
      }
    }
  }

  useEffect(() => {
    if (location.state) {
      if (location?.state?.data === "toSignup") {
        switchToSignUp();
      }
    }
  }, [location.state]);

  const boxStyles = {
    // marginTop: "1rem",
    background: "white",
    padding: "40px",
    height: "auto",
    width: width > 800 ? "max-content" : "auto",
    borderRadius: "16px",
    boxShadow: "0 4px 8px rgba(16, 24, 40, 0.1)",
  };

  return (
    <>
      <div className={Classes.backgroundImage} >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
            ease: easeOut,
            delay: 0.3
          }}
        >
          <div className={Classes.navContainer} style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }} >
            <div>
              <a className={Classes.logoLink} href="/">
                <img className={Classes.logoImage} src='/Blue.png' alt="Logo" />
              </a>
            </div>
            <nav className={Classes.navMenu}>

            </nav>
            <div>
              <a href="/">
                <h5 className={Classes.goBackTxt} >Go back to evala.ai</h5>
              </a>
            </div>
          </div>

        </motion.div>
        <section className={`${Classes.section}`}>
          <AnimatePresence mode="wait">
            {state === "signin" && (
              <motion.div
                key="signIn"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{
                  duration: 0.3,
                  ease: easeOut,
                }}
              >
                <form onSubmit={MoveToSubdomain} className={Classes.boxStyles}>
                  <a href="/" className={Classes.miniLogo} >
                    <img src="/site.png" alt="logo" />
                  </a>
                  <h1 className={Classes.heading}>Welcome Back</h1>
                  <p className={Classes.subtitle}>Provide your account name to continue</p>
                  <br />
                  <div className={`${Classes.passwordCon} ${incorrectError ? Classes.inputError : ''}`} >
                    <input
                      onChange={(e) => setCompanyName(e.target.value)}
                      type="text"
                      placeholder="Account Name"
                      required
                    />
                    <div className={Classes.preDomain} > <h5>.evala.ai</h5> </div>
                  </div>
                  {incorrectError && <p className={Classes.error}>*Username or Password is incorrect</p>}
                  <div className={Classes.flexBetween} >
                    <div className={Classes.flexAlign} >
                      {/* <input type="checkbox" id="inputField" className={Classes.check} />
                    <label htmlFor="inputField">
                      <button className={Classes.forgotPasswordButton} style={{ cursor: 'default', color: 'black' }} >
                        Remember me
                      </button>
                    </label> */}
                    </div>
                    <button type="button" onClick={() => setState('forgot')} className={Classes.forgotPasswordButton}>
                      Forgot account name?
                    </button>
                  </div>
                  <button type="submit" className={`${Classes.button} ${Classes.signInButton}`} disabled={loading}>
                    Sign in
                    {loading && <LoadingSpinner />}
                  </button>
                  <p className={Classes.signupLink2}>
                    Don't have an account?
                    <button type="button" onClick={switchToSignUp} className={Classes.linkButton}>
                      Sign up
                    </button>
                  </p>
                </form>
              </motion.div>
            )}
            {state === "forgot" && (
              <motion.div
                key="forgot"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{
                  duration: 0.3,
                  ease: easeOut,
                }}
              >
                <form onSubmit={ForgotHandler} className={Classes.boxStyles}>
                  <a href="/" className={Classes.miniLogo} >
                    <img src="/site.png" alt="logo" />
                  </a>
                  <h1 className={Classes.heading}>Look up your account name</h1>
                  <p className={Classes.subtitle}>Enter your email address and we will send you your account name.</p>
                  <br />
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter your email"
                    required
                    className={`${Classes.inputField} ${incorrectError ? Classes.inputError : ''}`}
                  />
                  {invalidEmailError && <p className={Classes.error}>*Enter valid registered email.</p>}
                  {emailSentMsg && <p className={Classes.success}>*Link to reset your password was sent to your email.</p>}
                  <button type="submit" className={`${Classes.button} ${Classes.signInButton}`} disabled={loading} >
                    Continue
                    {loading && <LoadingSpinner />}
                  </button>
                  <p onClick={() => setState("signin")} className={Classes.signupLink2} style={{ cursor: 'pointer' }} >
                    Back to sign in page
                  </p>
                </form>
              </motion.div>
            )}
          </AnimatePresence>
        </section>
      </div>
    </>
  );
}

export default Auth;
