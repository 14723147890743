import React, { useState, useEffect } from 'react';
import Classes from './Footer.module.css'
import { motion, easeOut } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigateTo = useNavigate();
  const location = useLocation();

  const navigateToSignup = () => {
    navigateTo('/signup', { state: { data: "toSignup" } });
  }

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleClick = (value) => {
    if (location.pathname !== '/') {
      localStorage.setItem('navScroll', value);
      navigateTo('/');
    } else {
      scrollToElement(value);
    }
  }

  const scrollToElement = (elementId) => {
    const targetElement = document.getElementById(elementId);

    if (targetElement) {
      const offset = 50;
      const targetPosition = targetElement.offsetTop + offset;

      window.scroll({
        top: targetPosition,
        behavior: "smooth",
      });
    }
  };


  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.5,
        ease: easeOut,
        delay: 0.3,
      }}
    >
      <footer className={Classes.footer}>
        <div className={Classes.card} >
          <img src='/logowhite.png' className={Classes.logo} alt='logo' />
          <div className={Classes.flexBetween} >
            <div className={Classes.list} >
              <div className={Classes.listCon} >
                <h5 className={Classes.title} > Product </h5>
                <a href='/demo' target='_blank' >
                  <h5 className={Classes.text} > Demo </h5>
                </a>
                <h5 className={Classes.text} onClick={() => handleClick("features")} > Features </h5>
                <h5 className={Classes.text} onClick={() => handleClick("how-it-works")} > How it Works </h5>
                <h5 className={Classes.text} onClick={() => handleClick("pricing")} > Pricing </h5>
              </div>
              <div className={Classes.listCon} >
                <h5 className={Classes.title} > Company </h5>
                <a href='https://www.evala.ai/blog' target='_blank' >
                  <h5 className={Classes.text} > Blog </h5>
                </a>
                <h5 className={Classes.text} onClick={() => handleClick("questions")} > FAQ </h5>
                <a href='/contact-us' target='_blank' >
                  <h5 className={Classes.text} > Contact </h5>
                </a>
                <h5 className={Classes.text} > Career </h5>
              </div>
              <div className={Classes.listCon} >
                <h5 className={Classes.title} > Legal </h5>
                <a href='/data-security' target='_blank' >
                  <h5 className={Classes.text} > Data Security </h5>
                </a>
                <a href='/terms-of-service' target='_blank' >
                  <h5 className={Classes.text} > Terms of Service </h5>
                </a>
                <a href='/privacy-policy' target='_blank' >
                  <h5 className={Classes.text} > Privacy Policy </h5>
                </a>
              </div>
            </div>
            <div>
              <h5 className={`${Classes.title} ${Classes.center}`} >Backed by</h5>
              <div className={Classes.flexAlign} >
                <img src='/fau.png' alt='fau logo' className={Classes.partnersLogo} />
                <img src='/microsoft.png' alt='microsoft logo' className={Classes.partnersLogo} />
              </div>
            </div>
          </div>
          <hr className={Classes.line} />
          <div className={Classes.flexBetween} >
            <h5 className={Classes.text} >© 2024 Evala.ai, All rights reserved.</h5>
            <div className={Classes.flexAlign} >
              <a className={Classes.socialIcon} href="https://www.linkedin.com/company/pitchperfecter-ai/" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" fill='#0262DF' viewBox="0 0 448 512"><path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg>
              </a>
              {/* <a className={Classes.socialIcon} href="https://www.instagram.com/pitchperfecter.ai/" target="_blank" rel="noopener noreferrer">
                <svg xmlns="http://www.w3.org/2000/svg" fill='#0262DF' viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
              </a> */}
            </div>
          </div>
        </div>
        <div className={Classes.banner} >
          <div className={Classes.bannerInner} >
            <div>
              <h1 className={Classes.bannerTitle} >Save time and money <br /> screening pitch decks</h1>
              <div className={Classes.flexAlign2} >
                <div className={Classes.flexAlign3} >
                  <img src='/icons/tickBlue.png' style={{ height: '20px' }} alt='tick' />
                  <h5 className={`${Classes.text} ${Classes.m0}`} >10 times faster</h5>
                </div>
                <div className={Classes.flexAlign3} >
                  <img src='/icons/tickBlue.png' style={{ height: '20px' }} alt='tick' />
                  <h5 className={`${Classes.text} ${Classes.m0}`} >100 times cheaper</h5>
                </div>
              </div>
            </div>
            <div>
              <motion.div
                whileTap={{ scale: 0.97 }}
                whileHover={{ scale: 1.03 }}
              >
                <button className={Classes.blueBtn} onClick={navigateToSignup} > <h5>Get Started Free</h5> </button>
              </motion.div>
              <h5 className={`${Classes.miniText} ${Classes.center} ${Classes.m0}`} >
                No credit card required
              </h5>
            </div>
            <img className={Classes.footerWave} src='/footer-wave.png' alt='footer-wave' />
          </div>
        </div>
      </footer>
    </motion.div>
  );
};

export default Footer;
