import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Classes from './PricingAndFaqs.module.css';
import './pricing.css';
import { motion, AnimatePresence } from 'framer-motion';

import {
  Button,
  ListItem,
  ListItemIcon,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { AnimateTopToBottom } from './Animations/AnimateTopToBottom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SectionE = () => {
  const sectionRef = useRef(null); // Reference to the section
  const [isYearly, setisYearly] = useState(true);
  const [expanded, setExpanded] = useState('');
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const navigate = useNavigate();

  const navigateToSignup = () => {
    navigate('/login', { state: { data: "toSignup" } });
  }

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const { user } = useSelector((state) => state.auth);

  const faqs = [
    {
      question: "Can I use this software for free?",
      answer: "Yes, you can use evala.ai for free. It is a software that allows you to screen and filter submitted pitch decks. The free plan includes access to all premium features but is limited to registrations for up to 5 companies. You can upgrade to the Basic or Pro version for additional companies."
    },
    {
      question: "How do applicants access my landing page?",
      answer: "Once on-boarded, you will receive a unique landing page link. You can find this link at the top of your screening dashboard and in your account settings. If you need to change your landing page link, you can do so in your account settings."
    },
    {
      question: "Is your AI model trained on user data?",
      answer: "No, we do not use user data to train our AI model. The model is trained by analyzing over 20,000 venture capital blogs, investment memos, and successful pitch decks."
    },
    {
      question: "What do you do with the uploaded pitch decks?",
      answer: "Decks are stored securely and only accessible by our team for admin purposes only. They are not used to train our AI model and can be deleted upon request. We never share your deck with anyone and periodically delete all decks uploaded."
    },
    {
      question: "How secure is the data?",
      answer: "At evala.ai, we understand the importance of protecting your data and ensuring the privacy of your information. Our platform is built upon industry-leading technologies which incorporate the latest security best practices to safeguard your data. For more information, you can visit Data Security."
    }
  ];

  useEffect(() => {
    // Effect will trigger when `isYearly` changes
  }, [isYearly]);

  return (
    <section id='pricing' ref={sectionRef} className={Classes.section}>
      <div className={Classes.card}>
        <h1 className={Classes.subheading}> Plans for all ecosystem sizes. </h1>
        <div className={Classes.flex} >
          <div className={Classes.pricingBtns} >
            <button onClick={() => setisYearly(false)} className={!isYearly ? Classes.activeButton : Classes.inactiveButton}>
              <h5>Monthly</h5>
            </button>
            <button onClick={() => setisYearly(true)} className={isYearly ? Classes.activeButton : Classes.inactiveButton}>
              <h5>Yearly</h5>
            </button>
          </div>
          <h5 className={Classes.saveText} >Save 15% by billing annually</h5>
        </div>

        {isYearly ?
          <div className={Classes.boxesList} >
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{
                  delay: 0,
                  duration: 0.3,
                }}
              >
                <div className={Classes.boxWhite} >
                  <h5 className={Classes.boxMiniTitle} >Basic</h5>
                  <h5 className={Classes.priceText} > Free </h5>
                  <div className={Classes.list} >
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >1 member seat</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >10 companies per month</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >Unique landing page</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >White-label screening tool</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >Personalized AI scoring</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >Comprehensive deck analysis</h5>
                    </div>
                  </div>
                  <div className={Classes.blueBtn} onClick={navigateToSignup} >
                    <h4>Get started for free</h4>
                  </div>
                  <h5 className={`${Classes.text} ${Classes.center}`} >No credit card required</h5>
                </div>
              </motion.div>
            </AnimatePresence>
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{
                  delay: 0.1,
                  duration: 0.3,
                }}
              >
                <div className={Classes.boxBlue} >
                  <h5 className={Classes.boxMiniTitle} >Pro</h5>
                  <h5 className={Classes.priceText} > {isYearly ? '$99' : '$114'} <span>/month</span> </h5>
                  <div className={Classes.list} >
                    <div className={Classes.listItem} >
                      <img src='/icons/tick.png' alt='tick' />
                      <h5 className={Classes.text} >10 member seats</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tick.png' alt='tick' />
                      <h5 className={Classes.text} >100 companies per month</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tick.png' alt='tick' />
                      <h5 className={Classes.text} >Unique landing page</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tick.png' alt='tick' />
                      <h5 className={Classes.text} >White-label screening tool</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tick.png' alt='tick' />
                      <h5 className={Classes.text} >Personalized AI scoring</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tick.png' alt='tick' />
                      <h5 className={Classes.text} >Comprehensive deck analysis</h5>
                    </div>
                  </div>
                  <div className={Classes.whiteBtn} onClick={navigateToSignup} >
                    <h4>Get started for free</h4>
                  </div>
                  <h5 className={`${Classes.text} ${Classes.center}`} >No credit card required</h5>
                </div>
              </motion.div>
            </AnimatePresence>
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 10 }}
                transition={{
                  delay: 0.2,
                  duration: 0.3,
                }}
              >
                <div className={Classes.boxWhite} >
                  <h5 className={Classes.boxMiniTitle} >Enterprise</h5>
                  <h5 className={Classes.priceText} > $199 <span>/month</span> </h5>
                  <div className={Classes.list} >
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >Unlimited member seats</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >Unlimited company limits</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >Unique landing page</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >White-label screening tool</h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >Personalized AI scoring </h5>
                    </div>
                    <div className={Classes.listItem} >
                      <img src='/icons/tickBlue.png' alt='tick' />
                      <h5 className={Classes.text} >Comprehensive deck analysis </h5>
                    </div>
                  </div>
                  <div className={Classes.blueBtn} onClick={navigateToSignup} >
                    <h4>Get started for free</h4>
                  </div>
                  <h5 className={`${Classes.text} ${Classes.center}`} >No credit card required</h5>
                </div>
              </motion.div>
            </AnimatePresence>
          </div >
          :
          <div className={Classes.boxesList} >
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{
                delay: 0,
                duration: 0.3,
              }}
            >
              <div className={Classes.boxWhite} >
                <h5 className={Classes.boxMiniTitle} >Basic</h5>
                <h5 className={Classes.priceText} > Free </h5>
                <div className={Classes.list} >
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >1 member seat</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >10 companies per month</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >Unique landing page</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >White-label screening tool</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >Personalized AI scoring</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >Comprehensive deck analysis</h5>
                  </div>
                </div>
                <div className={Classes.blueBtn} onClick={navigateToSignup} >
                  <h4>Get started for free</h4>
                </div>
                <h5 className={`${Classes.text} ${Classes.center}`} >No credit card required</h5>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{
                delay: 0.1,
                duration: 0.3,
              }}
            >
              <div className={Classes.boxBlue} >
                <h5 className={Classes.boxMiniTitle} >Pro</h5>
                <h5 className={Classes.priceText} > {isYearly ? '$99' : '$114'} <span>/month</span> </h5>
                <div className={Classes.list} >
                  <div className={Classes.listItem} >
                    <img src='/icons/tick.png' alt='tick' />
                    <h5 className={Classes.text} >10 member seats</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tick.png' alt='tick' />
                    <h5 className={Classes.text} >100 companies per month</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tick.png' alt='tick' />
                    <h5 className={Classes.text} >Unique landing page</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tick.png' alt='tick' />
                    <h5 className={Classes.text} >White-label screening tool</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tick.png' alt='tick' />
                    <h5 className={Classes.text} >Personalized AI scoring</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tick.png' alt='tick' />
                    <h5 className={Classes.text} >Comprehensive deck analysis</h5>
                  </div>
                </div>
                <div className={Classes.whiteBtn} onClick={navigateToSignup} >
                  <h4>Get started for free</h4>
                </div>
                <h5 className={`${Classes.text} ${Classes.center}`} >No credit card required</h5>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{
                delay: 0.2,
                duration: 0.3,
              }}
            >
              <div className={Classes.boxWhite} >
                <h5 className={Classes.boxMiniTitle} >Enterprise</h5>
                <h5 className={Classes.priceText} > $228 <span>/month</span> </h5>
                <div className={Classes.list} >
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >Unlimited member seats</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >Unlimited company limits</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >Unique landing page</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >White-label screening tool</h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >Personalized AI scoring </h5>
                  </div>
                  <div className={Classes.listItem} >
                    <img src='/icons/tickBlue.png' alt='tick' />
                    <h5 className={Classes.text} >Comprehensive deck analysis </h5>
                  </div>
                </div>
                <div className={Classes.blueBtn} onClick={navigateToSignup} >
                  <h4>Get started for free</h4>
                </div>
                <h5 className={`${Classes.text} ${Classes.center}`} >No credit card required</h5>
              </div>
            </motion.div>
          </div >
        }

        <div id='questions' className={Classes.faqs}>
          <h1 className={Classes.subheading}> FAQs </h1>
          <div className={Classes.faqsCon}>
            {faqs.map((faq, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index + 1}`}
                onChange={handleChange(`panel${index + 1}`)}
                sx={{
                  border: '1px solid #80808024',
                  boxShadow: 'rgb(0 0 0 / 13%) 0px 1px 2px',
                  marginBottom: '1rem',
                  borderRadius: '7px !important',
                  padding: '6px 20px !important',
                  '&:before': {
                    display: 'none',
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index + 1}bh-content`}
                  id={`panel${index + 1}bh-header`}
                  sx={{
                    minHeight: '32px !important',
                    margin: '0 !important',
                    padding: '0px 10px !important',
                    '&.Mui-expanded': {
                      margin: '0 !important',
                      minHeight: '32px !important',
                      padding: '0px 10px !important',
                    },
                    '.MuiAccordionSummary-content': {
                      margin: '10px 0 !important'
                    },
                    '.MuiAccordionDetails-root': {
                      padding: '8px 12px 16px !important'
                    },
                  }}
                >
                  <div className={Classes.AccordionHeader}>
                    <h5>{faq.question}</h5>
                  </div>
                </AccordionSummary>
                <AccordionDetails className={Classes.accordinBody}
                  sx={{
                    padding: '8px 16px 16px 10px !important',
                  }}
                >
                  <h5 className={Classes.accordionText}>{faq.answer}</h5>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div >
    </section >
  );
};

export default SectionE;
